:root {
  scroll-behavior: smooth;
}

body {
  color: #444;
  background: #f6f9ff;
  font-family: Open Sans, sans-serif;
}

a {
  color: #4154f1;
  text-decoration: none;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Nunito, sans-serif;
}

#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all .3s;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  color: #012970;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
}

.back-to-top {
  visibility: hidden;
  opacity: 0;
  z-index: 99999;
  width: 40px;
  height: 40px;
  background: #4154f1;
  border-radius: 4px;
  transition: all .4s;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.back-to-top i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}

.back-to-top:hover {
  color: #fff;
  background: #6776f4;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.dropdown-menu {
  border: 0;
  border-radius: 4px;
  padding: 10px 0;
  animation-name: dropdown-animate;
  animation-duration: .2s;
  animation-fill-mode: both;
  box-shadow: 0 5px 30px #523f6933;
}

.dropdown-menu .dropdown-header, .dropdown-menu .dropdown-footer {
  text-align: center;
  padding: 10px 25px;
  font-size: 15px;
}

.dropdown-menu .dropdown-footer a {
  color: #444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  transition: all .3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow:before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

.card {
  border: none;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 0 30px #0129701a;
}

.card-header, .card-footer {
  color: #798eb3;
  background-color: #fff;
  border-color: #ebeef4;
  padding: 15px;
}

.card-title {
  color: #012970;
  padding: 20px 0 15px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px;
}

.card-img-overlay {
  background-color: #fff9;
}

.alert-heading {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  box-shadow: none;
  outline: 0;
}

.accordion-item {
  border: 1px solid #ebeef4;
}

.accordion-button:focus {
  box-shadow: none;
  outline: 0;
}

.accordion-button:not(.collapsed) {
  color: #012970;
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  background: none;
  border: 0;
  padding: 15px 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #4154f1;
}

.accordion-flush .accordion-body {
  color: #3e4f6f;
  padding: 0 0 15px;
  font-size: 15px;
}

.breadcrumb {
  color: #899bbd;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: all .3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item:before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  color: #2c384e;
  border: none;
  margin-bottom: -2px;
}

.nav-tabs-bordered .nav-link:hover, .nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  color: #4154f1;
  background-color: #fff;
  border-bottom: 2px solid #4154f1;
}

.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo img {
  max-height: 26px;
  margin-right: 6px;
}

.logo span {
  color: #012970;
  font-family: Nunito, sans-serif;
  font-size: 26px;
  font-weight: 700;
}

.header {
  z-index: 997;
  height: 60px;
  background-color: #fff;
  padding-left: 20px;
  transition: all .5s;
  box-shadow: 0 2px 20px #0129701a;
}

.header .toggle-sidebar-btn {
  cursor: pointer;
  color: #012970;
  padding-left: 10px;
  font-size: 32px;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    padding: 20px;
    transition: all .3s;
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    box-shadow: 0 0 15px #0129701a;
  }

  .header .search-bar-show {
    visibility: visible;
    opacity: 1;
    top: 60px;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  color: #012970;
  width: 100%;
  border: 1px solid #01297033;
  border-radius: 3px;
  padding: 7px 38px 7px 8px;
  font-size: 14px;
  transition: all .3s;
}

.header .search-form input:focus, .header .search-form input:hover {
  border: 1px solid #0129704d;
  outline: none;
  box-shadow: 0 0 10px #01297026;
}

.header .search-form button {
  background: none;
  border: 0;
  margin-left: -30px;
  padding: 0;
}

.header .search-form button i {
  color: #012970;
}

.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  color: #012970;
  font-size: 20px;
}

.header-nav .nav-profile {
  color: #012970;
}

.header-nav .nav-profile img {
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 14px;
  font-weight: 600;
}

.header-nav .badge-number {
  padding: 3px 6px;
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  top: 4px;
  bottom: auto;
  left: auto;
  right: 6px;
}

.header-nav .notifications .notification-item {
  align-items: center;
  padding: 15px 10px;
  transition: all .3s;
  display: flex;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.header-nav .notifications .notification-item p {
  color: #919191;
  margin-bottom: 3px;
  font-size: 13px;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: all .3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  max-height: 40px;
  margin: 0 20px 0 10px;
}

.header-nav .messages .message-item h4 {
  color: #444;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.header-nav .messages .message-item p {
  color: #919191;
  margin-bottom: 3px;
  font-size: 13px;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
}

.header-nav .profile .dropdown-header h6 {
  color: #444;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  transition: all .3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

.sidebar {
  width: 300px;
  z-index: 996;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  background-color: #fff;
  padding: 20px;
  transition: all .3s;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 0 20px #0129701a;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {
  #main, #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main, .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav, .sidebar-nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  text-transform: uppercase;
  color: #899bbd;
  margin: 10px 0 5px 15px;
  font-size: 11px;
  font-weight: 600;
}

.sidebar-nav .nav-link {
  color: #4154f1;
  transition: .3;
  background: #f6f9ff;
  border-radius: 4px;
  align-items: center;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
}

.sidebar-nav .nav-link i {
  color: #4154f1;
  margin-right: 10px;
  font-size: 16px;
}

.sidebar-nav .nav-link.collapsed {
  color: #012970;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform .2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  margin: 0;
  padding: 5px 0 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  color: #012970;
  transition: .3;
  align-items: center;
  padding: 10px 0 10px 40px;
  font-size: 14px;
  font-weight: 600;
  transition: all .3s;
  display: flex;
}

.sidebar-nav .nav-content a i {
  border-radius: 50%;
  margin-right: 8px;
  font-size: 6px;
  line-height: 0;
}

.sidebar-nav .nav-content a:hover, .sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}

.dashboard .filter {
  position: absolute;
  top: 15px;
  right: 0;
}

.dashboard .filter .icon {
  color: #aab7cf;
  padding-bottom: 5px;
  padding-right: 20px;
  font-size: 16px;
  transition: all .3s;
}

.dashboard .filter .icon:hover, .dashboard .filter .icon:focus {
  color: #4154f1;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

.dashboard .info-card {
  padding-bottom: 10px;
}

.dashboard .info-card h6 {
  color: #012970;
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: 700;
}

.dashboard .card-icon {
  width: 64px;
  height: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 32px;
  line-height: 0;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  min-width: 64px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.dashboard .activity .activity-item .activite-label:before {
  content: "";
  width: 4px;
  background-color: #eceefe;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -11px;
}

.dashboard .activity .activity-item .activity-badge {
  z-index: 1;
  border: 3px solid #fff;
  border-radius: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 3px;
  font-size: 11px;
  line-height: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-bottom: 20px;
  padding-left: 10px;
}

.dashboard .activity .activity-item:first-child .activite-label:before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

.dashboard .news .post-item + .post-item {
  margin-top: 15px;
}

.dashboard .news img {
  width: 80px;
  float: left;
  border-radius: 5px;
}

.dashboard .news h4 {
  margin-bottom: 5px;
  margin-left: 95px;
  font-size: 15px;
  font-weight: bold;
}

.dashboard .news h4 a {
  color: #012970;
  transition: all .3s;
}

.dashboard .news h4 a:hover {
  color: #4154f1;
}

.dashboard .news p {
  color: #777;
  margin-left: 95px;
  font-size: 14px;
}

.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0;
}

.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  max-width: 60px;
  border-radius: 5px;
}

.iconslist {
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
  display: grid;
}

.iconslist .icon {
  text-align: center;
  color: #012970;
  background-color: #fff;
  border-radius: .25rem;
  padding: 15px 0;
}

.iconslist i {
  margin: .25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
  padding: .25rem;
  font-size: 12px;
  display: inline-block;
  overflow: hidden;
}

.profile .profile-card img {
  max-width: 120px;
}

.profile .profile-card h2 {
  color: #2c384e;
  margin: 10px 0 0;
  font-size: 24px;
  font-weight: 700;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.profile .profile-card .social-links a {
  color: #01297080;
  margin-right: 10px;
  font-size: 20px;
  line-height: 0;
  transition: all .3s;
  display: inline-block;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #012970;
}

.profile .profile-overview .label, .profile .profile-edit label {
  color: #01297099;
  font-weight: 600;
}

.profile .profile-edit img {
  max-width: 120px;
}

.faq .basic h6 {
  color: #4154f1;
  font-size: 18px;
  font-weight: 600;
}

.faq .basic p {
  color: #6980aa;
}

.contact .info-box {
  padding: 28px 30px;
}

.contact .info-box i {
  color: #4154f1;
  font-size: 38px;
  line-height: 0;
}

.contact .info-box h3 {
  color: #012970;
  margin: 20px 0 10px;
  font-size: 20px;
  font-weight: 700;
}

.contact .info-box p {
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
}

.contact .php-email-form .error-message {
  color: #fff;
  text-align: left;
  background: #ed3c0d;
  margin-bottom: 24px;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .sent-message {
  color: #fff;
  text-align: center;
  background: #18d26e;
  margin-bottom: 24px;
  padding: 15px;
  font-weight: 600;
  display: none;
}

.contact .php-email-form .loading {
  text-align: center;
  background: #fff;
  margin-bottom: 24px;
  padding: 15px;
  display: none;
}

.contact .php-email-form .loading:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  border-radius: 50%;
  margin: 0 10px -6px 0;
  animation: animate-loading 1s linear infinite;
  display: inline-block;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  color: #fff;
  background: #4154f1;
  border: 0;
  border-radius: 4px;
  padding: 10px 30px;
  transition: all .4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #5969f3;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-404 {
  padding: 30px;
}

.error-404 h1 {
  color: #4154f1;
  margin-bottom: 0;
  font-size: 180px;
  font-weight: 700;
  line-height: 150px;
}

.error-404 h2 {
  color: #012970;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 700;
}

.error-404 .btn {
  color: #fff;
  background: #51678f;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

.footer {
  border-top: 1px solid #cddfff;
  padding: 20px 0;
  font-size: 14px;
  transition: all .3s;
}

.footer .copyright {
  text-align: center;
  color: #012970;
}

.footer .credits {
  text-align: center;
  color: #012970;
  padding-top: 5px;
  font-size: 13px;
}

/*# sourceMappingURL=index.6b8648ae.css.map */
